<template>
  <div class="view pa24">

    <el-dialog
        :title="title"
        width="800px"
        :modal-append-to-body="false"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :before-close="closeDialog"
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="dealerRuleForm"
          label-width="100px"
          class="ruleForm"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="所属父级">
          <el-select class="main-select-tree" ref="selectTree" clearable @clear="setValueNull"
						v-model="parentName" style="width: 300px;" name='option 1'>
						<el-option v-for="item in optionData(classList)" :label="item.label" :value="item.id" :key="item.label"
							style="display: none;" />

						<el-tree class="main-select-el-tree" ref="selectelTree" :data="classList" :props='treeProps'
							highlight-current @node-click="handleNodeClick" :expand-on-click-node="expandOnClickNode"
							default-expand-all />
					</el-select>

        </el-form-item>
        <el-form-item label="排序" >
          <el-input type="number" v-model="ruleForm.sort"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button class="btncss" @click="confirmAddCustomer">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {addPageClass,pageClassList} from '@/api/customPageClass.js'

export default {
  name: "addPageClass",
  components: {
    // BaiduMap,
  },
  props: [],

  data() {
    return {
      //测试
      provinces: [],
      classType:null,
      dialogVisible: false,
      title: '添加类别',

      parentId: '',
      parentName: '',
      treeProps: {
        children: 'children',
        label: 'name'
      },
      expandOnClickNode: false,
      ruleForm: {
        name: '',
        sort:999,
        parentId:null
      },
      rules: {
        name: [
          {required: true, message: '请输入类别名称', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入联系电话', trigger: 'blur'},
          { type: 'number',
            message: '手机号格式不正确',
            trigger: 'blur',
            transform(value) {
              var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
              if (!phonereg.test(value)) {
                return false
              } else {
                return Number(value)
              }
            }
          }
        ]

      },
      type: 'add',
      id: '',
      classList:[{value:0,label:'fadsfs'}],

    };
  },
  onShow() {
  },
  methods: {
    handleCheckChange(node,list){
      this.ruleForm.parentId=node.id;
      //选中事件在选中后执行，当lis中有两个选中时，使用setCheckedKeys方法，选中一个节点
      if (list.checkedKeys.length == 2) {
        //单选实现
        this.$refs.selectTree.setCheckedKeys([node.id]);
      }
    },
    getAllClass(){
      let params={
        pageNum: 1,
        pageSize: 100000,
        type:0,
      }
      pageClassList(params).then(res => {
        this.classList = res.data.list
        console.log(this.classList)
      })
    },
    openDialog(type, data,classType) {
      this.getAllClass()
      this.dialogVisible = true
      let v = this
      v.classType = classType
      if (type === 'add') {
        this.title = '添加类别'
        this.type = 'add'
        this.id = ''
      } else {
        this.title = '编辑类别'
        this.getData(data)
        this.type = "edit"
        this.id = data.id
      }
    },
    handleNodeClick(node) {
      this.ruleForm.parentId = node.id
      this.parentName = node.name
      this.$refs.selectTree.blur();
    },
    setValueNull() {
      this.ruleForm.parentId ='';
      this.parentName = ''
    },
    optionData(array, result = []) {
				array.forEach(item => {
					result.push({
						label: item.name,
						value: item.id
					})
					if (item.children && item.children.length !== 0) {
						this.optionData(item.children, result)
					}
				})
				return JSON.parse(JSON.stringify(result))
			},
    closeDialog() {
      this.dialogVisible = false
      this.resetForm()
      this.$emit('getList')
    },
    resetForm() {
      this.$refs.dealerRuleForm.resetFields();
    },
    confirmAddCustomer() {
      this.$refs.dealerRuleForm.validate((valid) => {
        if (valid) {
          let params = {
            name: this.ruleForm.name,
            sort: this.ruleForm.sort,
            type:this.classType,
            parentId: this.ruleForm.parentId
          }
          if (this.type === 'edit') {
            params.id = this.id
            addPageClass(params).then(res => {
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: "操作成功",
                });
                this.closeDialog()
              }
            })
          }else {
            addPageClass(params).then(res => {
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: "操作成功",
                });
                this.closeDialog()
              }
            })
          }

        }
      })
    },
    // 回显
    getData(data) {
      this.ruleForm.name = data.name
      this.ruleForm.sort = data.sort
      this.ruleForm.parentId = data.parentId
      this.parentName = data.name
    },
  },


};
</script>

<style lang="scss" scoped>
.ml10 {
  margin-left: 10 rpx;
}
</style>
