<template>
  <div class="view pa24">
    <div class="d-flex">
      <el-row :gutter="24">
        <el-col :xl="24" :lg="16" class="d-flex justify-content-between w100p">
          <div class="d-flex align-items-center">
            <el-input
                v-model="name"
                class="mb10"
                style="width: 240px"
                placeholder="请输入类别名称"
                clearable
            />
            <el-button class="ml10 mb10" type="primary" @click="getCustomer">搜索</el-button>
            <el-button class="ml10 mb10" type="primary" @click="handleAddPageClass('add')">+添加类别</el-button>
          </div>
        </el-col>
      </el-row>
<!--        <el-button class="ma" type="primary" @click="handleAddCustomer('add')">+添加客户</el-button>-->
    </div>

    <el-table
      :data="tableData"
      style="width: 100%;margin-bottom: 20px;"
      row-key="id"
      border
      default-expand-all
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
          prop="name"
          align="center"
          label="名称"
        />
        <el-table-column
            prop="sort"
            align="center"
            label="排序"
        >
        </el-table-column>
        <el-table-column
            prop="createTime"
            align="center"
            label="创建时间"
        />
        <el-table-column align="center" width="200" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="editCustomer(scope.row)">编辑</el-button>
            <el-popconfirm confirm-button-text="确定" cancel-button-text="取消"
                              @confirm="delPageClass(scope.row)" icon="el-icon-info" icon-color="red" title="确定要删除吗？">
              <el-button slot="reference" class="ml10" style="color: #fd634e" type="text" >删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
    </el-table>

    <div class="pageBox">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20]"
        layout="total, sizes, prev, pager, next, jumper"
        class=""
        :total="total"
        :pager-count="5"
      />
    </div>
    


    <!-- <commonTable
      :tableData="tableData"
      :total="total"

      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:table>
        <el-table-column
          prop="name"
          align="center"
          label="名称"
        />
        <el-table-column
            prop="sort"
            align="center"
            label="排序"
        >
        </el-table-column>
        <el-table-column
            prop="createTime"
            align="center"
            label="创建时间"
        />
        <el-table-column align="center" width="200" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="editCustomer(scope.row)">编辑</el-button>
            <customPopconfirm confirm-button-text="确定" k cancel-button-text="取消"
                              @confirm="delPageClass(scope.row)" icon="el-icon-info" icon-color="red" title="确定要删除吗？">
              <el-button slot="reference" class="ml10" style="color: #fd634e" type="text" >删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable> -->

    <AddPageClassDialog ref="addPageClassDialog" @getList="getList"></AddPageClassDialog>
  </div>
</template>

<script>
import AddPageClassDialog from './components/AddPageClassDialog'
// import commonTable from "@/components/common/commonTable";
// import customPopconfirm from "@/components/common/customPopconfirm";
import { deleteCustomPageClass,pageClassList,noPage } from '@/api/customPageClass.js'
export default {
  components: {
    // commonTable,
    AddPageClassDialog,
    // customPopconfirm
  },
  props:{
    classType:{
      default:0
    }
  },
  data() {
    return {
      name:null,
        tableData: [],
        pageSize: 10,
        pageNum: 1,
        total: 0,
    };
  },
  created() {
    this.getList()
  },
  methods: {
    getCustomer(){
      this.getList()
    },
      handleSizeChange(num) {
        this.pageSize = num;
        this.pageNum = 1;
        this.getList();
      },
      handleCurrentChange(num) {
        this.pageNum = num;
        this.getList();
      },
       handleAddPageClass(type, id) {
          this.$refs.addPageClassDialog.openDialog(type, id,this.classType)
      },
      getList() {
        let params={
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          type:this.classType,
          name:this.name
        }
        pageClassList(params).then(res => {
          this.tableData = res.data.list
          this.total = res.data.total
        })
      },
    editCustomer(data) {
        this.$refs.addPageClassDialog.openDialog('edit', data,this.classType)
      },
    delPageClass(data) {
			console.log(data)
      deleteCustomPageClass(data.id).then(res => {
          if(res.code === 200) {
            this.$message({
                type: "success",
                message: "操作成功",
            });
            this.getList()
          }else {
						this.$message({
						    type: "error",
						    message: res.message,
						});
					}
        })
      },
      lookImg(row) {
        this.$router.push({ path: '/classImgList', query: {
          id: row.id,
          name: row.name,
        },})
      }
  },

}
</script>

<style lang="scss" scoped>
.ml10{
  margin-left: 10px;
}
.pageBox{
  display: flex;
  justify-content: flex-end;
}
</style>

